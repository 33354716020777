<template>
    <div>
       <div v-if="policyInfo" :class="['policy', {'hidden':show != true}]">
           <button class="hide" @click="show = false">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                    <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                </svg>
           </button>
            <a href="#" class="change link collapsed" data-toggle="collapse" data-target="#searchcollapse" aria-expanded="false" aria-controls="#searchcollapse" @click="$emit('searchcollapse', showTourismSearchForm = !showTourismSearchForm)">{{ stepBtn }}</a>
            <div class="title d-lg-block">Параметри пошуку</div>
            <hr class="d-none d-lg-block">
            <div class="specific d-lg-block">
                <b>Країна</b>
                <p>{{policyInfo.country.name}}</p>
            </div>
            <div  class="specific d-lg-block">
                <b>Термін поїздки</b>
                <p>{{policyInfo.days}}</p>
            </div>
            <div class="specific d-lg-block">
               <b>Тип страхування</b>
               <p>{{showType(policyInfo.yearProgram)}}</p>
            </div>
            <div class="specific d-lg-block">
               <b>Кількість та вік туристів</b>
               <p>{{policyInfo.tourists}}</p>
            </div>
            <div v-if="offerInfo && step != 'result'" class="specific d-lg-block">
               <b>Ліміт покриття</b>
               <p>{{offerInfo.limit}} {{ offerInfo.limit_currency | currencySign }}</p>
            </div>
           <div v-if="offerInfo && step != 'result'" class="specific d-lg-block result__param param-price">
             <b>Загальна вартість</b>
             <div class="heading result__price">
               <div v-if="offerInfo.dynamicDiscountPrice > 0 || offerInfo.discountPercent > 0" class="old">
                 <span>{{ offerInfo.price }} <span>грн</span></span>
                 <b v-if="offerInfo.dynamicDiscountedSum > 0">-{{ offerInfo.dynamicDiscountedSum }} грн</b>
                 <b v-else >-{{ offerInfo.discountPercent }}%</b>
                 <i
                     data-placement="bottom"
                     data-toggle="tooltip"
                     title=""
                     class="icon question"
                     data-original-title="Знижка надається безпосередньо за наш рахунок від ціни, що надається страховою компанією. Ми забезпечуємо найнижчу ціну на ринку. Вартість поліса вказана з урахуванням знижки."></i>

               </div>

               <p v-if="offerInfo.dynamicDiscountPrice > 0 || offerInfo.discountPrice > 0" class="price value red">{{ showDiscountPrice(offerInfo) }} грн </p>
               <p v-else class="price">{{ showPrice(offerInfo) }} грн</p>
             </div>
           </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        data: () => ({
            show: true,
            showTourismSearchForm: false,
            step: false,
            FullPrice: 0,
        }),
        props: ['dataStep','dataFullPrice'],

        watch: {
            dataFullPrice:function (newVAl,oldVal) {
                this.FullPrice = newVAl;
            }
        },
        created() {

        },
        mounted: function () {
            this.fetchData();
        },

        computed: {
            stepBtn(){
                const data = {'result':'Змінити','steps':''};
                return data[this.step];
            },
            policyInfo() {
                return this.$store.state.tourismStore.searchTourismParams;
            },
            offerInfo() {
                return this.$store.getters.GET_TOURISM_INFO_OFFER;
            },
        },

        methods: {
            showPrice(offer){
              return Number(offer.price) ;
            },
            showDiscountPrice(offer){
              return (offer.prolongOrderId) ? offer.price: Math.round(Number(offer.price - Number(offer.dynamicDiscountedSum || 0)));
            },
            showType(type) {
                if (!type) {
                    return 'Одноразовий'
                } else {
                    return `Річна`
                }
            },
            scrollTo(item){
                let el = item;
                $('html,body').animate({
                    scrollTop: $(el).offset().top + 150
                }, 1000);
            },
            fetchData() {
                this.step = this.dataStep || 'result';
                this.FullPrice = this.dataFullPrice || 0;
            },
        }
    }
</script>
