<template>
    <div :class="['modal-overlay', {'active' : showModal}]"  @click.stop>
        <div :class="['set-discount-container',  {'active' : showModal}]" :style="{ top: '50%', left: '50%', position: 'fixed', 'z-index': 10000 }" @click.stop>
            <form  class="transaction-form" @submit.prevent="send">
                <div v-if="this.preloader" class="modal-preloader">
                    <div class="spinner"></div>
                </div>
                <button type="button" class="hide" @click="closeModal">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
                        <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
                    </svg>
                </button>
                <div class="transaction-form__heading">Введіть ціну полісу</div>
                <div class="transaction-form__descr">Вкажіть бажану ціну полісу</div>
                <div class="form-group">
                    <input v-model="paySum" type="text" placeholder="сумма" :class="['form-control', {invalid: ($v.paySum.$error  && submited)}]">
                      <vue-slider v-model.lazy="paySum"
                          tooltip="none"
                          :min="Math.ceil(minValueForPaySum)"
                          :max="Math.floor(maxValueForPaySum)"
                          :interval="calculateInterval(Math.ceil(minDiscountPrice), Math.floor(maxValueForPaySum))">
                      </vue-slider>
                  <small>Не менше нетто-платежу та не більше повної ціни полісу.</small>
                    <small class="error" v-if="!$v.paySum.min && submited">Cума повинна бути більше {{minValueForPaySum}} грн</small>
                    <small class="error" v-else-if="!$v.paySum.max && submited">Cума повинна бути меньше {{maxValueForPaySum}} грн</small>
                    <small class="error" v-else-if="$v.paySum.$dirty && !$v.paySum.required && submited">Введіть бажану суму</small>
                    <small class="error" v-else-if="(($v.paySum.$dirty && !$v.paySum.minLength) || ($v.paySum.$dirty && !$v.paySum.maxLength)) && submited">Введіть коректну суму</small>
                </div>
                <!--<div class="modal-transaction__value__summ" v-if="paySum != 0">
                    <b>Сума переказу:</b>
                    <span>{{paySum}} грн.</span>
                </div>-->
                <div class="transaction-form__btns">
                    <button type="submit" class="btn-blue" :readonly="!isValidPaySum" :disabled="!isValidPaySum">
                        Застосувати ціну
                    </button>
                    <button type="button" class="btn-white" @click="closeModal">Скасувати</button>
                </div>
            </form>
        </div>
    </div>

</template>

<script>
    import VueSlider from 'vue-slider-component'
    import {required, minValue, maxValue, minLength, maxLength} from 'vuelidate/lib/validators'

    export default {
        components: {VueSlider},
        props: {
          offer: Object,
        },
        data: function () {
            return {
                paySum: null,
                startOfferPrice: null,
                preloader: false,
                submited:false,
                showModal: false,
                money: {
                    decimal: ',',
                    thousands: ' ',
                    prefix: '',
                    precision: 0,
                    masked: false /* doesn't work with directive */
                },
            }
        },

        validations: {
          paySum:{
            min: function(value) {
              if (!value) return true;
              return value >= this.minDiscountPrice;
            },
            max: function(value) {
              if (!value) return true;
              return value <= this.maxValueForPaySum;
            }
          },
        },
        mounted() {
            this.$root.$on('showDiscountSet', () => {
                this.toggleModal();
            })
            //this.paySum = this.maxValueForPaySum;
        },
        computed: {
          startPaySum(){
            return this.paySum;
          },
          isValidPaySum() {
            if (!this.paySum) return true;
            return this.paySum >= this.minValueForPaySum && this.paySum <= this.maxValueForPaySum;
          },
          minDiscountPrice() {
            if (this.offer && this.offer.earnings) {
              const discount = this.offer.discountPrice > 0
                  ? this.offer.discountPrice
                  : this.offer.price;
              return Math.max(1, (discount - this.offer.earnings));
            }
            return 1;
          },
          minValueForPaySum() {
            return this.minDiscountPrice || 1;
          },
          maxValueForPaySum() {
              return this.startOfferPrice || (this.offer.price - this.dgoAutolayerPrice(this.offer));
          }
        },
        watch:{
          "paySum":function (){
            this.submited = false;
          },
          offer: {
            handler(newOffer) {
              if(newOffer.showModal){
                this.showModal = newOffer.showModal;
                this.paySum = (Number(newOffer.dynamicDiscountPrice) || Number(newOffer.discountPrice) || Math.floor(Number(this.maxValueForPaySum)));
                this.startOfferPrice = (Number(this.offer.price) - Number(this.dgoAutolayerPrice(newOffer)));
              }
            },
            deep: true
          }
        },
        methods: {
          dgoAutolayerPrice(offer){
            return (Number(offer?.autolawyerPrice) || 0) +  (Number(offer?.dgoPrice) || 0);
          },
          calculateInterval(min, max) {
            if (!min || min >= max) return 10;
            let difference = max - min;
            let interval = 10;

            while (difference % interval !== 0) {
              interval--;
              if (interval <= 1) break;
            }
            return interval;
          },
          closeModal() {
            this.showModal = false;  // Закрываем попап
            this.$emit('update:offer', { ...this.offer, showModal: false });
          },
          send() {
                this.submited = true;

                if (this.$v.$invalid) {
                    console.log(this.$v);
                    this.$v.$touch()
                    return;
                }

                this.showModal = false;
                this.preloader = true;

                if(this.paySum == this.maxValueForPaySum || !this.paySum){
                  this.$emit('update:offer', { ...this.offer, discountPrice: 0, discountPercent:0, discountedSum:0, earnings: 0  });
                }else{

                  let discountPercent = (this.startOfferPrice ? (100 - (this.startPaySum * 100 / this.startOfferPrice)) : 0).toFixed(10);
                  let discountedSum = (this.startOfferPrice - this.startPaySum).toFixed(10);
                  let kvp = ((this.offer.earnings / this.startOfferPrice) * 100).toFixed(10);
                  let kv = ((this.startOfferPrice * kvp) / 100).toFixed(10);
                  let kvd = (kv - discountedSum).toFixed(2);
                  let kvdp = (kvd/this.startOfferPrice*100).toFixed(2);

                  this.$emit('update:offer', { ...this.offer, discountPrice: this.paySum, discountPercent:Number(discountPercent).toFixed(2), discountedSum:Number(discountedSum).toFixed(2), earnings: kvd  });
                }

                ///////////////////////////////////

                this.preloader = false;
                this.paySum = null;
            },

            toggleModal(){
                this.showModal = !this.showModal;
            },

        }
    }
</script>


<style lang="scss" scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.custom-modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.custom-modal-container {
    .policy-link {
        width: 436px;
        height: 64px;
        background: #F6F7FB;
        border-radius: 5px;
        display: flex;
        @media (max-width: 992px) {
            width: 300px;
        }

        .link {
            margin: auto 0 auto 12px;
            white-space: nowrap;
            overflow: hidden;
            max-width: 67%;
            padding: 5px;
            text-overflow: ellipsis;

            @media (max-width: 992px) {
                margin: auto 0 auto 2px;
            }
        }
    }
    .message {
        width: 258px; height: 29px;font-family: Circe,serif;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 29px;
        text-align: center;
        color: #24282C;

        @media (max-width: 992px) {
            width: 258px;
            height: 21px;
            font-size: 17px;
            line-height: 20px;
        }
    }

    .mtsbu-icon {
        margin: auto 0 auto 20px;

        @media (max-width: 992px) {
            margin: auto 0 auto 5px;
        }
    }

    @media (max-width: 992px) {
        width:358px;
    }
    width: 516px;
    height: 217px;
    background: #FFFFFF;
    border-radius: 5px;
    margin: 0 auto;
    padding: 20px 30px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.custom-modal-custom-header h3 {
    margin-top: 0;
    color: #42b983;
}

.custom-modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .custom-modal-container,
.modal-leave-active .custom-modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.set-discount-container .transaction-form::before{
  display:none!important;
}
.transaction-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.transaction-form__btns button:disabled {
  background-color: light-dark(rgba(179, 179, 179, 0.3), rgba(28, 10, 10, 0.3))!important;
  color: light-dark(rgba(16, 16, 16, 0.3), rgba(255, 255, 255, 0.3))!important;
  border-color: light-dark(rgba(118, 118, 118, 0.3), rgba(149, 148, 148, 0.3))!important;
}

</style>

